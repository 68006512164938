/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, SeparateLine, SeparateLineWrap, Title, Image, Subtitle, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-1qcsern --parallax pb--30" name={"introduction"} style={{"paddingTop":70}} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"marginTop":10}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--center ff--2 fs--48 w--900 text-box--invert swpf--uppercase ls--08" style={{"marginTop":12}} content={"Ekaterina &amp; Kun<br>wedding<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 pt--0" style={{"marginTop":50,"paddingTop":null}} columns={"2"}>
          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"n7bibw72p5"} layout={"l4"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="mb--30 mt--40" name={"about-us"} style={{"marginTop":null,"paddingTop":null,"paddingLeft":null,"marginBottom":null,"paddingBottom":null}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 mb--0 pb--0 pl--40 pr--40" style={{"maxWidth":null,"paddingLeft":null,"marginBottom":null,"paddingRight":null,"paddingBottom":null}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--2 fs--36 w--900 ls--004" content={"<span style=\"font-style: italic; font-weight: normal;\">Dear family and friends,</span>"}>
              </Title>

              <Text className="text-box ff--2 fs--20 w--600 lh--16" style={{"marginTop":20}} content={"<span style=\"color: var(--color-custom-1); font-weight: normal;\">Thank you for the love, laughter, support and encouragement you've given us. <br>You are our favourite people in the world and we feel blessed to meet our happily ever after with you!</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ar49rlv0trh"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/33279/3ee3867f56db448f9b1bb06472df8fe5_s=660x_.jpeg"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/33279/3ee3867f56db448f9b1bb06472df8fe5_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/33279/3ee3867f56db448f9b1bb06472df8fe5_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/33279/6e35910024c94aca8054573b1f43363d_s=660x_.jpeg"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/33279/6e35910024c94aca8054573b1f43363d_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/33279/6e35910024c94aca8054573b1f43363d_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/33279/320b7dd35a904127882274c73151409f_s=660x_.jpeg"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/33279/320b7dd35a904127882274c73151409f_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/33279/320b7dd35a904127882274c73151409f_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/33279/320b7dd35a904127882274c73151409f_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/33279/bb7a1724251c4d159079687f9ca7a88f_s=660x_.jpeg"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/33279/bb7a1724251c4d159079687f9ca7a88f_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/33279/bb7a1724251c4d159079687f9ca7a88f_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/33279/182ee7a87a8c43b1ad362b298fb00dd1_s=660x_.jpeg"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/33279/182ee7a87a8c43b1ad362b298fb00dd1_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/33279/182ee7a87a8c43b1ad362b298fb00dd1_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/33279/0a2c3aeb05f14682ab2d58072fc5dc1b_s=660x_.jpeg"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/33279/0a2c3aeb05f14682ab2d58072fc5dc1b_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/33279/0a2c3aeb05f14682ab2d58072fc5dc1b_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pl--20" name={"information"} style={{"marginTop":0,"paddingTop":64,"paddingLeft":null,"marginBottom":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --full --center el--1 pl--40 pr--40" style={{"marginTop":0,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--2 fs--48 w--300 ls--004 mt--0 pb--0 pt--0" style={{"marginTop":null,"paddingTop":null,"paddingBottom":null}} content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">You can look forward to</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"information-2"} style={{"paddingTop":3,"paddingBottom":80,"backgroundColor":"rgba(255, 255, 255, 1)"}} layout={"l10"}>
          
          <ColumnWrap className="column__flex el--3 pl--40 pr--40" style={{"paddingLeft":null,"paddingRight":null}} columns={"3"}>
            
            <ColumnWrapper className="column__flex--top --center">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Hotel/hotel-4.svg"} style={{"maxWidth":100}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box ff--2 fs--36 w--300" style={{"marginTop":0,"marginBottom":0}} content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">Island weekends</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="column__flex--top --center">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/4245602635b04eb1af32c41fbe7bb1e8.svg"} style={{"maxWidth":100}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box ff--2 fs--36 w--300" style={{"marginTop":0,"marginBottom":0}} content={"<span style=\"color: rgb(0, 0, 0); font-style: italic;\">Games and surprises</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="column__flex--top --center">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/33279/1989bd8542034bbeab05c59ab498eb37_bri=0_sat=0_.png"} style={{"maxWidth":100}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box ff--2 fs--36 w--300" style={{"marginTop":0,"marginBottom":0}} content={"<span style=\"font-style: italic;\">Cultural experience</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sxmmf6q6p"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"font-style: italic;\">Schedule</span>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--center ff--2" content={"Marriage Ceremony"}>
              </Subtitle>

              <Text className="text-box text-box--center ff--2 fs--22" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">20.11.2023\n<br>Haiyang Marriage Office\n</span><br>&nbsp;21 Nanjing Street, Haiyang, Shandong, China, 265106<br><br></span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--center ff--2" content={"Grand Wedding"}>
              </Subtitle>

              <Text className="text-box text-box--center ff--2 fs--22" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">26.11.2023\n<br>Sanya Marriott Yalong Bay Resort &amp; Spa\n</span><br>1 Wan Yue Lu, Yalong Bay, Sanya, Hainan, China, 572000<br><br></span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--center ff--2" content={"Traditional Wedding"}>
              </Subtitle>

              <Text className="text-box text-box--center ff--2 fs--22" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">29.11.2023\n<br>Shenglong Jianguo Hotel\n</span><br>187 Haibin Zhong Road, Shandong, China, 265118</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"zpevlyaecgq"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Ekaterina"}>
              </Title>

              <Text className="text-box text-box--center ff--2 fs--20" style={{"maxWidth":399}} content={"<span style=\"color: var(--color-custom-1); font-style: italic;\">«This</span><span style=\"font-style: italic; color: var(--color-custom-1);\">&nbsp;was the boldest move of my life. <br><br>Without consulting almost anyone, <br><br>I bought tickets from Moscow to Paris and took<br><br>&nbsp;the risk to meet him in person. <br><br>And it turned out to be the best decision<br><br>&nbsp;I ever made</span><span style=\"color: var(--color-custom-1);\">»</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/33279/6e3587057c844e369ebe10312784fba7_e=34x230x780x780_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33279/6e3587057c844e369ebe10312784fba7_e=34x230x780x780_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/33279/6e3587057c844e369ebe10312784fba7_e=34x230x780x780_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/33279/0918035ffebf4bd8bc7d59419c3adc80_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33279/0918035ffebf4bd8bc7d59419c3adc80_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/33279/0918035ffebf4bd8bc7d59419c3adc80_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Kun"}>
              </Title>

              <Text className="text-box ff--2 fs--20" style={{"maxWidth":399}} content={"<span style=\"color: var(--color-custom-1); font-style: italic;\">«Thanks to fate, I can't imagine a more blessed<br><br>&nbsp;life than the one I have with her. <br><br>Thanks to her trust, bravery, and<br><br>&nbsp;sincerity, we will be together to share <br><br>a wonderful future in life»</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"divider"} style={{"paddingTop":0,"marginBottom":0,"paddingBottom":0}} layout={"l4"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"photogallery-2"} style={{"paddingTop":30,"paddingBottom":60}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1qcsern --parallax pl--20" name={"picture"} style={{"marginTop":0,"paddingTop":90,"paddingLeft":null,"marginBottom":0,"paddingBottom":50}} layout={"l1"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33279/e730a7ed33d942dab7af76382eabd56b_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --full --center el--1 mb--0 pl--40 pr--40" style={{"paddingLeft":null,"marginBottom":null,"paddingRight":null,"paddingBottom":20}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--2 fs--43 w--300 title-box--invert ls--004" content={"<span style=\"font-style: italic;\">We're very grateful you chose to spend the day with us! <br><br>You can leave us a message if you wish!</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"contact"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36 w--300" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"font-style: italic;\">We look forward to seeing you!</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style2 --right" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Full name","type":"text","required":true,"placeholder":"Enter your full name"},{"name":"Contact e-mail address:","type":"email","required":true,"placeholder":"Enter your e-mail"},{"name":"Telephone number","type":"text","placeholder":"Telephone No."},{"name":"Message","type":"textarea","required":true,"placeholder":"Enter your message"},{"name":"Send","type":"submit","align":"center"}]} layout={"l3"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}